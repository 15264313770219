<template>
  <div class='SelectDrop'>
    <button type="text" class='btn btn-info btn-sm'
      @click='openselect()'>{{(btntext)?btntext:langpublic.selectcountry}}</button>
    <div class='SD-box border shadow rounded' v-if='clicktoggle' style=''>
      <div class="SD-header">
        <div class="w-75 float-left">
          <select class='form-control' v-model='filtercountry'>
            <option value="">{{langpublic.selecttext}}</option>
            <option :value="group.value" v-for='group in listdata'>{{group.group}}</option>
          </select>
        </div>

        <div class='float-right'>
          <font-awesome-icon :icon="['fas', 'times-circle']" size="lg" class='text-danger cur-print' @click='closewindow()'
            alt='取消' />
        </div>
      </div>
      <div class="row clearfix" v-for='list in listdatacomputed'>
        <div class="col-12 pl-1">
          {{list.group}}
        </div>

        <div class="col-4 cur-print">
          <input type="checkbox" class="oc" :value='list.value+"-all"' v-icheckStyle='checkedlist'
            :checked='checkedlist.indexOf(list.value+"-all")>-1' name='Talent'>
          <label for="oop">全部</label>
        </div>

        <div class="col-4 cur-print" v-for='data in list.sub'>
          <input type="checkbox" class="oc" :class='"SD-"+list.value+"-"+data.value' :value="list.value+'-'+data.value"
            v-icheckStyle='checkedlist'
            :checked='checkedlist.indexOf(list.value+"-"+data.value)>-1'>
          <label :for='"SD-"+list.value+"-"+data.value'>{{data.name}}</label>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import filter from "@/service/filter/filter.sub";
  import store from "@/store";
  import moment from "moment"

  const listMethods = {
    openselect() {
      Promise.all(this.openfunc()).then((data) => {
        console.log(data);
        // country & city
        this.listdata = data;
        this.clicktoggle = !this.clicktoggle;
      })
    },
    closewindow(){
       this.clicktoggle=false;
    }
  }
  let nameComputed = {
    listdatacomputed() {
      return (this.filtercountry) ? filter.filter(this.listdata, {
        value: this.filtercountry
      }) : this.listdata
    }
  }
  export default {
    name: 'SelectDrop',
    data() {
      console.log(this);
      const today = moment();
      return {
        langpublic: store.state.Public.lang.public,
        clicktoggle: false,
        listdata: [],
        filtercountry: ""
      };;
    },
    methods: listMethods,
    computed: nameComputed,
    props: {
      checkedlist: Array,
      openfunc: Function,
      btntext: {
        type: String
      }
    },
  };
</script>